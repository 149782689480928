import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'

import Hero from "../components/sections/hero/hero"

import Layout from "../components/theme/theme"

import "../components/sections/section/section.css"
import "../components/sections/section/bullet-list.css"

const IndexPage = props => {
  const heroImages = [
    props.data.mobileHero.childImageSharp.fluid,
    {
      ...props.data.desktopHero.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <Helmet title="List of the services provided" />

      <Hero image={heroImages} textcolor="light" subtitle="Building and maintenance contractors" title="Services we provide" />

      <section className="sec-Section">
        <div className="sec-Section_Inner lyt-Container">
          <div className="sec-Section_Header">
            <h3 className="sec-Section_SubTitle">
              List of our services
            </h3>

            <h2 className="sec-Section_Title">
              We will provide a fully managed service
            </h2>
          </div>

          <div className="sec-Section_Body">
            <p>CBW Limited is able to provide attendance to all trades and services normally found in domestic properties. We directly employ operatives covering our main trades. With all individuals able to provide a specialist skill base, whilst still undertaking a multi-skilled operation, repeat visits and unnecessary delays in completion are avoided.</p>
            
            <p>However, our full-time labour force can not cover all specialist trades of services and we utilise the services of additional contractors, with whom we have built a reliable long-term relationship and who provide CBW with the same standard of services as provided by ourselves, whilst still under the direct responsibility and management of CBW.</p>

            <ul className="sec-BulletList_Items">
              <li className="sec-BulletList_Item">
                Carpentry
              </li>

              <li className="sec-BulletList_Item">
                Decoration
              </li>

              <li className="sec-BulletList_Item">
                Plumbing - Heating, gas, investigation works
              </li>

              <li className="sec-BulletList_Item">
                Electrical - Including testing works
              </li>

              <li className="sec-BulletList_Item">
                Roofing
              </li>

              <li className="sec-BulletList_Item">
                Drainage - Investigation, unblocking and remedial works
              </li>

              <li className="sec-BulletList_Item">
                Carpet Laying - Cleaning, relaying and repair
              </li>

              <li className="sec-BulletList_Item">
                Plastering
              </li>

              <li className="sec-BulletList_Item">
                Floor and Wall Tiling
              </li>

              <li className="sec-BulletList_Item">
                CCTV and Door Entry Systems
              </li>

              <li className="sec-BulletList_Item">
                Birchwork
              </li>

              <li className="sec-BulletList_Item">
                Groundwork - Paving, fencing
              </li>

              <li className="sec-BulletList_Item">
                Structural Repairs and Investigation
              </li>

            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    desktopHero: file(relativePath: { eq: "services-hero.jpg" }) {
      ...fluidImage
    }
    mobileHero: file(relativePath: { eq: "services-hero-mobile.jpg" }) {
      ...fluidImage
    }
  }
`;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2880) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
